@import "colors";

nav {
  background-color: $white;
  border-bottom: 1px solid $light-gray;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.logo-letter-mark {
  width: 275px;

  @include maxScreen($smallWidth) {
    width: 250px;
  }
}

.nav__pad_left {
  padding-left: 2em;

  @include maxScreen($smallWidth) {
    padding: inherit;
  }
}

.nav__hide_small {
  @include maxScreen($smallWidth) {
    display: none;
  }
}
