@import "colors";
@import "responsive";

.share-icon {
  font-size: 3em;

  @include maxScreen($smallWidth) {
    font-size: 1.5em;
  }
}

.share-icon-background-disc {
  color: $gray;
}

.share-icon-foreground-item {
  color: white;
  text-decoration: none;

  &:hover {
    color: $buttonClickColor;
  }

  &:active {
    color: $buttonColor;
  }
}

.explain-icon {
  font-size: 2.5em;

  @include maxScreen($smallWidth) {
    font-size: 1.75em;
  }
}

.explain-icon-background {
  color: $light-gray;
}

.explain-icon-foreground-red {
  color: $red;
}

.explain-icon-foreground-green {
  color: $green;
}

.explain-icon-foreground-blue {
  color: $blue;
}

.explain-arrow-icon {
  font-size: 3em;
}
