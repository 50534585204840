@import "colors";
@import "responsive";

.hero-container {
  background-color: #fff;
  background-image: url(../images/HeroBackground.jpg);
  background-position: 55% 25%;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  padding: 7em 10em 3em 10em;
  text-align: left;

  @include betweenScreen($smallWidth, $mediumWidth) {
    padding: 5em 5em 2em 5em;
  }

  @include maxScreen($smallWidth) {
    margin-bottom: 1.25em;
    padding: 4em 2em 1em 2em;
  }

  h1 {
    color: $white;
  }

  p {
    font-weight: 400;
    text-shadow: 0 0 5px rgba(0, 0, 0, 1.0);
  }

  strong {
    font-weight: 900;
  }

  .hero-copy {
    max-width: 720px;

    @include minScreen($hugeWidth) {
      max-width: 900px;
    }
  }
}

.hero-dark-back {
  background-color: rgba(0,0,0,0.70);
  box-shadow: 0.5em 0 0 rgba(0,0,0,0.70), -0.5em 0 0 rgba(0,0,0,0.70);
  display: inline;
  padding: 0.25em 0em;
  line-height: 2.0em;
}

.hero-background-about {
  background-image: url(../images/HeroAbout.jpg);
}

.hero-background-contact {
  background-image: url(../images/HeroContact.jpg);
}

.hero-background-privacy-terms {
  background-image: url(../images/HeroPrivacyAndTerms.jpg);
}

.hero-background-news {
  background-image: url(../images/HeroNews.jpg);
}

.hero-background-daily-emails {
  background-image: url(../images/HeroDailyEmails.jpg);
}
