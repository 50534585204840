@import "colors";

.button {
  background-color: $buttonColor;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 2.0em;
  font-weight: 900;
  outline: none;
  padding: 0.5em 1.0em 0.5em 1.0em;
  text-align: center;
  transition: all 150ms;

  &:hover {
    color: $buttonClickColor;
  }

  &:active {
    color: $buttonColor;
    background-color: $buttonClickColor;
  }

  @include maxScreen($smallWidth) {
    font-size: 1.5em;
  }
}
