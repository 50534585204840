@import "colors";
@import "responsive";

footer {
  background-color: $gray;
  color: $white;
  margin: 0;
  padding: 1.5em 3.5em 1.5em 3.5em;

  @include maxScreen($smallWidth) {
    padding: 1em 1.75em 3em 1.75em;
  }

  @include minScreen($hugeWidth) {
    padding: 2em 5em 2em 5em;
  }

  h1 {
    color: $white;
  }

  a {
    color: $white;
    display: block;
    font-size: 1.5em;
    text-decoration: underline;
    text-decoration-color: $gray;

    &:hover {
      color: $buttonClickColor;
      cursor: pointer;
      text-decoration-color: $buttonClickColor;
    }

    &:active {
      color: $buttonColor;
      text-decoration-color: $buttonClickColor;
    }

    @include maxScreen($smallWidth) {
      font-size: 1.0em;
    }

    @include betweenScreen($smallWidth, $mediumWidth) {
      font-size: 1.25em;
    }

    @include minScreen($hugeWidth) {
      font-size: 1.5em;
    }
  }

  .entry__center_vert {
    @include maxScreen($smallWidth) {
      justify-items: inherit;
    }
  }
}

.logo-letter-mark__footer {
  width: 275px;

  @include maxScreen($smallWidth) {
    width: 200px;
  }

  @include betweenScreen($smallWidth, $mediumWidth) {
    width: 200px;
  }

  @include minScreen($hugeWidth) {
    width: 300px;
  }
}

.entry__footer_group {
  padding-bottom: 1em;

  @include maxScreen($smallWidth) {
    padding-bottom: 0.25em;
  }
}

.entry__footer_horz_center_left {
  justify-items: center;

  @include maxScreen($smallWidth) {
    justify-items: start;
  }
}
