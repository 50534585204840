$hugeWidth: 1600px;
$mediumWidth: 1024px;
$smallWidth: 650px;

@mixin minScreen($res) {
  @media screen and (min-width: $res) {
    @content;
  }
}

@mixin maxScreen($res) {
  @media screen and (max-width: $res) {
    @content;
  }
}

@mixin betweenScreen($resMin, $resMax) {
  @media screen and (min-width: $resMin) and (max-width: $resMax) {
    @content;
  }
}
