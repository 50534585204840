$green: #00AE83;
$red: #EF513E;
$blue: #05BAEA;
$yellow: #FAEB4A;

$white: #fff;
$light-gray: #EFEFEF;
$gray: #42443E;
$black: #111;

$blueDarker: darken($blue, 20%);
$blueLighter: lighten($blue, 20%);

$grayDarker: darken($gray, 20%);
$grayLighter: lighten($gray, 20%);

$inputBorder: #cdcdcd;

$buttonColor: $blue;
$buttonHoverColor: lighten($buttonColor, 15%);
$buttonClickColor: darken($buttonColor, 20%);
