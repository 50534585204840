@import "colors";
@import "responsive";

body {
  background-color: $white;
  font-family: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
}

section {
  margin: 0;
  padding: 2.0em 3.5em 2.0em 3.5em;

  @include maxScreen($smallWidth) {
    padding: 1em 1.75em 1em 1.75em;
  }

  @include betweenScreen($smallWidth, $mediumWidth) {
    padding: 1.5em 2.75em 1.0em 2.75em;
  }

  @include minScreen($hugeWidth) {
    padding: 5em;
  }
}

h1 {
  color: $gray;
  font-size: 4.0em;
  font-weight: 900;
  margin: 0;
  padding: 0.5em 0 0.5em 0;

  @include maxScreen($smallWidth) {
    font-size: 2.25em;
    padding: 0.25em 0 0.25em 0;
  }

  @include betweenScreen($smallWidth, $mediumWidth) {
    font-size: 3em;
  }

  @include minScreen($hugeWidth) {
    font-size: 5.25em;
  }
}

h2 {
  color: $gray;
  font-size: 2.5em;
  font-weight: 700;
  margin: 0;
  padding: 0.5em 0 0.5em 0;

  @include maxScreen($smallWidth) {
    font-size: 1.75em;
    padding: 0.25em 0 0.25em 0;
  }

  @include betweenScreen($smallWidth, $mediumWidth) {
    font-size: 2.0em;
  }

  @include minScreen($hugeWidth) {
    font-size: 3.0em;
    line-height: 1.5em;
  }
}

h3 {
  color: $black;
  font-size: 1.75em;
  font-weight: 300;
  margin: 0;
  padding: 0.75em 0 0.75em 0;

  @include maxScreen($smallWidth) {
    font-size: 1.25em;
  }

  @include betweenScreen($smallWidth, $mediumWidth) {
    font-size: 1.5em;
  }

  @include minScreen($hugeWidth) {
    font-size: 2.25em;
    line-height: 1.5em;
    padding: 0.5em 0 0.5em 0;
  }
}

h4 {
  font-size: 1.0em;
  font-weight: 700;
  margin: 0;
  padding: 0 0 0.75em 0;
  text-transform: uppercase;


  @include maxScreen($smallWidth) {
    font-size: 1.0em;
    padding: 0.5em 0 0.5em 0;
  }

  @include betweenScreen($smallWidth, $mediumWidth) {
    font-size: 1.0em;
  }

  @include minScreen($hugeWidth) {
    font-size: 1.75em;
    line-height: 1.5em;
    padding-bottom: 0.5em;
  }
}

.entry__image {
  width: 350px;

  @include maxScreen($smallWidth) {
    display: block;
    margin: 0 auto;
    width: 256px;
  }

  @include betweenScreen($smallWidth, $mediumWidth) {
    width: 275px;
  }

  @include minScreen($hugeWidth) {
    width: 512px;
  }
}

p, li, label {
  font-size: 1.5em;
  font-weight: 400;
  margin: 0;

  @include maxScreen($smallWidth) {
    font-size: 1.25em;
  }

  @include betweenScreen($smallWidth, $mediumWidth) {
    font-size: 1.25em;
  }

  @include minScreen($hugeWidth) {
    font-size: 2.0em;
  }
}

p, li, label {
  padding-bottom: 0.75em;
  line-height: 1.25em;

  @include maxScreen($smallWidth) {
    line-height: 1.5em;
    padding-bottom: 0.5em;
  }
}

select, textarea, input[type="text"] {
  border: 3px solid $inputBorder;
  border-radius: 3px;
  box-sizing: border-box;
  color: #555;
  font-size: 2.0em;
  margin-top: 0.5em;
  padding: 0.5em;
  width: 100%;

  &:focus {
    box-shadow: 0 0 8px $blue;
  }

  @include maxScreen($smallWidth) {
    font-size: 1.25em;
    padding: 0.25em;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 0;
}

.hidden {
  display: none;
}

.centered {
  text-align: center;
}

.left-aligned {
  text-align: left;
}

strong {
  font-weight: 400;
}

a {
  color: $gray;
  text-decoration: none;

  &:hover {
    color: $buttonHoverColor;
    cursor: pointer;
  }

  &:active {
    color: $buttonClickColor;
  }
}

.entry-container {
  max-width: 1280px;
  margin: 0 auto;
}

.entry {
  display: grid;
}

.entry-1col__equal {
  grid-template-columns: 1fr;
}

.entry-2col__equal {
  grid-template-columns: 1fr 1fr;

  @include maxScreen($smallWidth) {
    grid-template-columns: 1fr;
  }
}

.entry-2col__left_big_right {
  grid-template-columns: 1fr 3fr;

  @include maxScreen($smallWidth) {
    grid-template-columns: 1fr;
  }
}

.entry-2col__big_left_right {
  grid-template-columns: 3fr 1fr;

  @include maxScreen($smallWidth) {
    grid-template-columns: 1fr;
  }
}

.entry-3col__equal {
  grid-template-columns: 1fr 1fr 1fr;

  @include maxScreen($smallWidth) {
    grid-template-columns: 1fr;
  }
}

.entry-4col__equal {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.entry-5col__equal {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @include maxScreen($smallWidth) {
    grid-row-gap: 0.5em;
    grid-template-columns: 1fr;
  }
}

.entry__center_vert {
  align-items: center;
}

.entry__center_horz {
  justify-items: center;
}

.entry__pad_left {
  padding-left: 3em;
}

.entry__pad_top {
  padding-top: 1em;

  @include maxScreen($smallWidth) {
    padding-top: 0.5em;
  }
}

.item__center_horz {
  justify-self: center;
}

.item__drop_high {
  @include maxScreen($smallWidth) {
    grid-row-start: 1;
  }
}

.note-text {
  font-size: 1.25em;
  margin: 0 auto;
  padding-top: 1em;
  width: 70%;

  @include maxScreen($smallWidth) {
    font-size: 1em;
    width: 100%;
  }

  @include betweenScreen($smallWidth, $mediumWidth) {
    font-size: 1em;
  }

  @include minScreen($hugeWidth) {
    font-size: 1.5em;
  }
}

.note-example {
  background-color: $light-gray;
  border-radius: 5px;
  padding: 0 0.25em 0 0.25em;
}

.social-sharing {
  background-color: $light-gray;
  color: $gray;
}

.social-button {
  font-size: 1em;
}

.note-text__mobile-only {
  display: none;

  @include maxScreen($smallWidth) {
    display: inherit;
  }
}

.note-text__alert {
  color: $buttonClickColor;
}

.ul__noted_list {
  list-style: inherit;
  padding: 0 0 1em 2em;

  @include maxScreen($smallWidth) {
    padding: 0 0 0.25em 0.5em;
  }
}

.note-text__icon {
  height: 1.25em;
  position: relative;
  top: 5px;
}