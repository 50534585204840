@import "colors";
@import "responsive";

.hunt-the-job-container {
  border-bottom: 4px solid $light-gray;
}

.search-input-group {
  padding: 0.5em 0 0.5em 0;
}

.hunt-the-job-how-action-button {
  color: $blue;
  margin-left: 0.25em;

  &:hover {
    color: $blueLighter;
  }

  &:active {
    color: $blueDarker;
  }
}

.hunt-the-job-missing-input {
  border-color: $red !important;
  color: $red;
  font-weight: 700;
}

.search-input-group input[type="checkbox"] {
  display: none;
  margin-right: 0.5em;
}

.search-input-group {
  label {
    position: relative;
  }

  span {
    &::before,
    &::after {
      // blank box behind check mark
      content: '';
      position: absolute;
      top: -18px;
      bottom: 0;
      margin: auto;

      @include maxScreen($smallWidth) {
        top: -9px;
      }

      @include betweenScreen($smallWidth, $mediumWidth) {
        top: -13px;
      }

      @include minScreen($hugeWidth) {
        top: -22px;
      }
    }
  }

  span {
    &.checkbox {
      &:hover {
        &::before {
          // hover-over state
          border: 3px solid $grayLighter;
        }
      }

      &::before {
        // unchecked
        background-color: $light-gray;
        border: 3px solid transparent;
        box-sizing: border-box;
        height: 27px;
        left: -35px;
        transition: 0.2s;
        width: 27px;
      }

      &::after {
        // checked
        color: transparent;
        content: '\f00d';
        font-family: 'FontAwesome', sans-serif;
        left: -31px;
        top: -2px;
        transition: 0.2s;

        @include maxScreen($smallWidth) {
          left: -30px;
          top: -3px;
        }

        @include betweenScreen($smallWidth, $mediumWidth) {
          left: -30px;
          top: 1px;
        }

        @include minScreen($hugeWidth) {
          left: -34px;
          top: 0;
        }
      }
    }
  }
}

input[type="checkbox"]:checked + label span.checkbox::after {
  color: $buttonClickColor;
}
